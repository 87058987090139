import { axiosInstance as axios } from '@/utils/Axios';
import { AgentResponse } from '@/model/AgentResponse';
import { ObjectiveDescription } from '@/model/ObjectiveDescription';


export const authenticate = async ({ username, password }) => {
  const formData = new FormData();
  formData.append('username', username);
  formData.append('password', password);
  const response = await axios.post('/auth/token', formData);
  return response.data;
}

export const getObjectivesForRole = async ({ role }) => {
  const response = await axios.post('/objectives', { role });
  return response.data.objectives.map(
    (objective) => new ObjectiveDescription(objective)
  );
};

export const sendObjectiveRequest = async ({
  interaction,
  objectiveDescription,
  inputValues,
}) => {
  if (Object.values(inputValues).some((value) => value instanceof File)) {
    return await sendObjectiveRequestWithFiles({
      interaction,
      objectiveDescription,
      inputValues,
    });
  }
  const body = {
    interaction,
    task_type: objectiveDescription.taskType,
    task_content: inputValues,
  };
  const result = await axios.post('/objective', body);
  return new AgentResponse(result.data);
};

export const sendObjectiveRequestWithFiles = async ({
  interaction,
  objectiveDescription,
  inputValues,
}) => {
  const formData = new FormData();
  formData.append('interaction', interaction);
  formData.append('task_type', objectiveDescription.taskType);
  for (const [key, value] of Object.entries(inputValues)) {
    formData.append(key, value);
  }
  const result = await axios.post('/objective_with_files', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return new AgentResponse(result.data);
}

export const sendUserResponse = async ({
  interaction,
  agentResponse,
  inputValues,
}) => {
  const body = {
    interaction,
    destination_task_type: agentResponse.originTaskType,
    responses: [
      {
        request: agentResponse.followUpRequests[0].id,
        input_values: inputValues,
      },
    ],
  };
  const result = await axios.post('/user_response', body);
  return new AgentResponse(result.data);
};
