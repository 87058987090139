import PropTypes from "prop-types";
import { ArrowDownOnSquareIcon } from "@heroicons/react/24/solid";
import "highlight.js/styles/github.css";
import FeatureFlags from "@/featureFlags";
import { markdownToHtmlConverter } from "@/utils/MarkdownParser";

export const MessageRow = ({
  userSent,
  message,
  onDownload,
  loading,
  hideAvatar,
  hideDownloadButton,
}) => {
  const textColour = userSent ? "text-white" : "text-slate-900";
  const bgColour = userSent ? "bg-primary-base" : "bg-slate-200";
  const messageClassName = `flex-1 rounded text-left px-3 py-2 whitespace-pre-line ${bgColour}`;

  const html = {
    __html: markdownToHtmlConverter.makeHtml(
      typeof message === "string" ? message : ""
    ),
  };

  return (
    <div className={`flex gap-x-2 ${textColour}`}>
      {!hideAvatar && (
        <div
          className={`text-sm rounded-full w-9 h-9 min-w-[2.25rem] min-h-[2.25rem] flex items-center justify-center sticky top-0 ${bgColour}`}
        >
          {userSent ? "ME" : "AI"}
        </div>
      )}

      <div className={messageClassName}>
        {loading && !userSent ? (
          'Generating a response...'
        ) : userSent ? (
          <div>{message}</div>
        ) : (
          <div id="renderedMarkdown" dangerouslySetInnerHTML={html}></div>
        )}
      </div>
      {!hideDownloadButton && (
        <div className="h-6 w-6 self-end">
          {FeatureFlags.downloadMessage && !userSent && !loading && (
            <ArrowDownOnSquareIcon
              className="text-slate-700 hover:text-slate-600 cursor-pointer transition ease-in-out"
              onClick={onDownload}
            />
          )}
        </div>
      )}
    </div>
  );
};

MessageRow.propTypes = {
  userSent: PropTypes.bool,
  message: PropTypes.any.isRequired,
  onDownload: PropTypes.func,
  loading: PropTypes.bool,
  objective: PropTypes.string,
  hideAvatar: PropTypes.bool,
  hideDownloadButton: PropTypes.bool,
};

MessageRow.defaultProps = {
  userSent: false,
  message: "",
  onDownload: null,
  loading: false,
  objective: "",
  hideAvatar: false,
  hideDownloadButton: false,
};
