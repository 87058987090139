import { useState, useEffect } from "react";
import { v4 as createUUID } from 'uuid';
import { Sidebar } from "@/components/Layout/Sidebar";
import { Whiteboard } from "@/components/Whiteboard";
import { WhiteboardEmptyState } from "@/components/WhiteboardEmptyState";
import { ToastContainer } from "react-toastify";

export const Home = () => {
  // Instance of ObjectiveDescription
  const [activeObjective, setActiveObjective] = useState(null);

  // Interaction UUID
  const [interaction, setInteraction] = useState(null);

  // Create a new interaction when the active objective changes
  useEffect(() => {
    if (activeObjective) createNewInteraction();
  }, [activeObjective]);

  const createNewInteraction = () => {
    setInteraction(createUUID());
  };

  const clearObjective = () => {
    setActiveObjective(null);
    setInteraction(null);
  };

  return (
    <div>
      {/* Sidebar - allows you to pick a role and objective */}
      <Sidebar
        activeObjective={activeObjective}
        setActiveObjective={setActiveObjective}
        resetMessageHistory={createNewInteraction}
      />

      <main
        className="pl-[480px] flex justify-center h-screen items-end"
      >
        {/* Whiteboard - shows agent interactions once an objective has been selected */}
        {activeObjective && interaction
          ? <Whiteboard 
              activeObjective={activeObjective}
              interaction={interaction} 
              onResetInteraction={createNewInteraction}
              onClearObjective={clearObjective}
            />
          : <WhiteboardEmptyState />
        }
      </main>

      {/* Toasts - used to display errors */}
      <ToastContainer
        position="bottom-left"
        closeButton={false}
        toastClassName="uli-toast"
        bodyClassName="uli-toast-body"
      />
    </div>
  );
};
