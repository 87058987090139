import PropTypes from 'prop-types';
import { Button } from './Common/Button';

export const WhiteboardInputsEmptyState = ({ onResetInteraction, onClearObjective }) => (
  <div className="grid grid-cols-[300px] gap-3 m-4 justify-center">
    <Button label="Restart Conversation" onClick={onResetInteraction} />
    <Button label="Clear Objective" onClick={onClearObjective} />
  </div>
);

WhiteboardInputsEmptyState.propTypes = {
  onResetInteraction: PropTypes.func.isRequired,
  onClearObjective: PropTypes.func.isRequired,
};
