import { Button } from "@/components/Common/Button";
import { InputField } from "@/components/Common/InputField";
import { useAuth } from "@/contexts/auth";
import { ArrowRightOnRectangleIcon } from "@heroicons/react/24/solid";
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from "react";
import { authenticate } from "@/modules/api";

const LoginText = () => (
  <div className="flex gap-2 items-center">
    <ArrowRightOnRectangleIcon className="w-5 h-5" />
    Login
  </div>
);

export const Login = () => {
  const { loggedIn, setJwt } = useAuth();
  const navigate = useNavigate();

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);

  useEffect(() => {
    if (loggedIn) {
      // Redirect to the review workflow
      // TODO: Redirect to a landing page instead.
      navigate('/');
    }
  }, [loggedIn, navigate]);

  const onSubmit = async () => {
    setError(null);
    try {
      const jwt = await authenticate({ username, password });
      setJwt(jwt);
    } catch (e) {
      console.error(e);
      setError('Invalid username or password');
    }
  };

  const onKeyDown = (e) => {
    if (e.key === 'Enter') {
      onSubmit();
    }
  };

  // Prevent any kind of rendering if the user is already logged in
  if (loggedIn) return null;

  return (
    <div className="bg-primary-base h-screen grid place-content-center" onKeyDown={onKeyDown}>
      <div className="w-[500px] bg-white shadow-xl p-10 rounded-lg">
        <div className="flex justify-center mb-6 gap-4 items-center text-xl font-bold">
          <img src="/logo.svg" alt="A2I2" className="invert" />
          Foundation Platform
        </div>
        <div className="grid gap-4">
          <InputField
            name="username"
            placeholder="Email"
            error={error}
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
          <InputField
            name="password"
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <Button label={<LoginText />} onClick={onSubmit} />
        </div>
      </div>
    </div>
  )
};
