import { useAuth } from "@/contexts/auth";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

export const Logout = () => {
  const { logout } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    logout();
    navigate("/login");
  }, [logout, navigate]);
};
