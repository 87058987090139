import { Fragment } from "react";
import { Listbox, Transition } from "@headlessui/react";
import PropTypes from "prop-types";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/24/solid";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export const InputSelect = ({
  label,
  value,
  options,
  placeholder,
  error,
  onChange,
  disabled,
  outlined,
}) => {
  const selectedOption = options.find((opt) => opt.value === value);
  const buttonClasses = classNames(
    "relative",
    "w-full rounded",
    "h-[50px]",
    "disabled:opacity-100 disabled:cursor-not-allowed",
    outlined ? "bg-white border border-primary-base text-black" : "bg-primary-base text-white",
    "pl-4",
    "pr-10",
    "py-2",
    "text-left",
    "cursor-default",
    "outline-offset-0",
    "focus:outline-1",
    "focus:outline-slate-300",
    "outline-none",
    outlined ? "" : "hover:bg-primary-light",
    error ? "border border-red-500" : "",
    selectedOption?.label ? "capitalize" : ""
  );

  return (
    <div className="grid gap-2">
      <Listbox value={value || ''} onChange={onChange} disabled={disabled}>
        {({ open }) => (
          <div>
            <Listbox.Label className="block text-white">
              {label}
            </Listbox.Label>
            <div className="mt-1 relative select-none">
              <Listbox.Button className={buttonClasses}>
                <span className="flex items-center">
                  <span
                    className={[
                      "ml block truncate",
                      selectedOption?.label ?? "text-white",
                    ].join(" ")}
                  >
                    {selectedOption?.label ??
                      placeholder ??
                      `Select ${label}...`}
                  </span>
                </span>
                <span className="ml-3 absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                  <ChevronDownIcon
                    className={`h-5 w-5 ${outlined ? 'text-black' : 'text-white'}`}
                    aria-hidden="true"
                  />
                </span>
              </Listbox.Button>

              <Transition
                show={open}
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Listbox.Options className="absolute z-10 mt-1 w-full bg-primary-base text-white rounded shadow-lg max-h-64 py-1 text-base ring-1 ring-slate-200 ring-opacity-5 overflow-auto focus:outline-none">
                  {options.map((option) => (
                    <Listbox.Option
                      key={option.value}
                      className={({ active }) =>
                        classNames(
                          active && "select-active",
                          "cursor-default relative py-2 pl-3 pr-9 hover:bg-primary-light transition ease-in-out capitalize"
                        )
                      }
                      value={option}
                    >
                      {({ selected, active }) => (
                        <>
                          <div className="flex items-center">
                            <span
                              className={classNames(
                                selected ? "font-semibold" : "font-normal",
                                "block truncate text-lg"
                              )}
                            >
                              {option.label ?? option.value}
                            </span>
                          </div>

                          {selected ? (
                            <span
                              className={classNames(
                                active ? "text-white" : "text-slate-600",
                                "absolute inset-y-0 right-0 flex items-center pr-4"
                              )}
                            >
                              <ChevronUpIcon
                                className="h-5 w-5"
                                aria-hidden="true"
                              />
                            </span>
                          ) : null}
                        </>
                      )}
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </Transition>
            </div>
          </div>
        )}
      </Listbox>
      {error && <p className="text-red-500 text-xs">{error}</p>}
    </div>
  );
};

InputSelect.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.any,
  options: PropTypes.array.isRequired,
  error: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  outlined: PropTypes.bool,
};
