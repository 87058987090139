import { toast } from "react-toastify";

export const showIncorrectApiKeyToast = () => {
  toast.error(
    <div>
      Incorrect API key provided. You can find your API key{" "}
      <a
        href="https://platform.openai.com/account/api-keys"
        target="_blank"
        rel="noreferrer"
        className="text-slate-300 hover:text-slate-400 font-bold transition ease-in-out"
      >
        here
      </a>
      .
    </div>
  );
};
