import PropTypes from "prop-types";
import { useState } from "react";
import { showIncorrectApiKeyToast } from "@/modules/errorToasts";
import { InputSelect } from "@/components/Common/InputSelect";
import { InfoButton } from "@/components/Common/InfoButton";
import { Roles } from "@/model/Roles";
import { ObjectiveDescription } from "@/model/ObjectiveDescription";
import { Loader } from "../Common/Loader";
import { getObjectivesForRole } from "@/modules/api";

export const Sidebar = ({
  resetMessageHistory,
  disabled,
  activeObjective,
  setActiveObjective,
}) => {
  const [role, setRole] = useState("");
  const [objectives, setObjectives] = useState(null);
  const [loading, setLoading] = useState(false);

  const onChangeRole = async (role) => {
    setRole(role);
    setObjectives(null);
    setLoading(true);

    try {
      const objectives = await getObjectivesForRole({ role });
      setObjectives(objectives);
    } catch (e) {
      console.error(e);
      setRole(null);
      showIncorrectApiKeyToast();
    }

    resetMessageHistory();
    setLoading(false);
  };

  return (
    <div className="fixed inset-y-0 z-50 flex w-[480px] flex-col">
      <div className="flex grow flex-col gap-y-2 bg-primary-dark px-6">
        <div className="flex h-16 shrink-0 items-center justify-center gap-x-2 select-none">
          <img className="h-8 w-auto" src="/logo.svg" alt="A2I2 logo" />
          <div className="text-white text-3xl font-extrabold">A2I2</div>
        </div>
        <div className="space-y-3">
          <InputSelect
            name="role"
            label="What is your role?"
            type="textarea"
            placeholder="Select a role for objectives..."
            options={Object.keys(Roles).map((role) => ({
              label: role.toLowerCase(),
              value: role.toLowerCase(),
            }))}
            value={role}
            onChange={(option) => {
              onChangeRole(option.value);
              setActiveObjective(null);
            }}
            disabled={disabled}
          />

          {objectives && objectives.map((objective) => (
            <InfoButton
              key={objective.taskType}
              info={objective.description}
              buttonText={objective.label}
              onClick={() => {
                setActiveObjective(objective);
                resetMessageHistory();
              }}
              active={activeObjective?.taskType === objective.taskType}
            />
          ))}
          {loading && (
            <div className="flex justify-center p-5">
              <Loader light />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

Sidebar.propTypes = {
  resetMessageHistory: PropTypes.func,
  disabled: PropTypes.bool,
  activeObjective: PropTypes.instanceOf(ObjectiveDescription),
  setActiveObjective: PropTypes.func,
};
