import PropTypes from 'prop-types';
import { useState, useEffect, useRef } from 'react';
import { PanelGroup, Panel, PanelResizeHandle } from 'react-resizable-panels';
import { ChevronUpDownIcon } from '@heroicons/react/24/solid';
import { sendObjectiveRequest, sendUserResponse } from '@/modules/api';
import { showIncorrectApiKeyToast } from '@/modules/errorToasts';
import { ObjectiveDescription } from '@/model/ObjectiveDescription';
import { ObjectiveInputFieldType } from '@/model/ObjectiveInputFieldType';
import { AgentResponse } from '@/model/AgentResponse';
import { WhiteboardItem } from '@/model/WhiteboardItem';
import { Avatar } from './Common/Avatar';
import { Loader } from './Common/Loader';
import { ChatView } from './Layout/ChatView';
import { ObjectiveInputFieldsRenderer } from './ObjectiveInputFieldsRenderer';
import { WhiteboardInputsEmptyState } from './WhiteboardInputsEmptyState';
import { getPathBasename } from '@/utils';

export const Whiteboard = ({ activeObjective, interaction, onResetInteraction, onClearObjective }) => {
  const [whiteboard, setWhiteboard] = useState([]);
  const [loading, setLoading] = useState(false);
  const interactionRef = useRef(interaction);

  // Clear the whiteboard when the interaction changes
  useEffect(() => {
    setWhiteboard([]);
    setLoading(false);
    interactionRef.current = interaction;

    const initialMessage = activeObjective?.initialMessage;
    if (initialMessage) {
      const item = new WhiteboardItem({ 
        content: initialMessage, 
        isAgent: true
      });
      setWhiteboard((prev) => [...prev, item]);
    }
  }, [interaction, activeObjective]);

  const loadingWrapper = (fn) => async (...args) => {
    setLoading(true);
    try {
      return await fn(...args);
    }
    catch (e) {
      console.error(e);
      showIncorrectApiKeyToast();
    } 
    finally {
      setLoading(false);
    }
  };

  const convertInputValuesToWhiteboardItem = ({inputFields, inputValues}) => {
    const showInputFields = inputFields
      .map((field) => ({
        ...field,
        value: inputValues[field.identifier],
      }))
      .filter((field) => field.value !== undefined && !field.hideInputValue);
    return showInputFields
      .map((field) => {
        switch (field.fieldType) {
          case ObjectiveInputFieldType.BUTTON:
            return `${field.label}`;
          case ObjectiveInputFieldType.FILE:
            return showInputFields.length === 1
              ? field.value.name ?? getPathBasename(field.value)
              : `${field.label}: ${field.value.name ?? getPathBasename(field.value)}`;
          default:
            return showInputFields.length === 1
              ? field.value 
              : `${field.label}: ${field.value}`;
        }
      })
      .map((content) => new WhiteboardItem({ content }));
  };

  const onSubmitObjectiveDirective = loadingWrapper(async (inputValues) => {
    // Add user input to the local whiteboard
    // TODO: Remove this when rendering of the whiteboard is controlled by the server.
    const newWhiteboardItems = convertInputValuesToWhiteboardItem({
      inputFields: activeObjective.inputFields,
      inputValues
    });
    const updatedWhiteboard = [...whiteboard, ...newWhiteboardItems];
    setWhiteboard(updatedWhiteboard);

    const prevInteraction = interaction;
    const response = await sendObjectiveRequest({
      interaction,
      objectiveDescription: activeObjective,
      inputValues,
    });
    if (prevInteraction !== interactionRef.current) {
      // The interaction has changed since the request was sent.
      return;
    }
    setWhiteboard([...updatedWhiteboard, response]);
  });

  const onSubmitUserResponse = loadingWrapper(async (agentResponse, inputValues) => {
    // Add user input to the local whiteboard
    // TODO: Remove this when rendering of the whiteboard is controlled by the server.
    const newWhiteboardItems = convertInputValuesToWhiteboardItem({
      inputFields: agentResponse.followUpRequests[0].requiredInputs,
      inputValues
    });
    const updatedWhiteboard = [...whiteboard, ...newWhiteboardItems];
    setWhiteboard(updatedWhiteboard);

    const prevInteraction = interaction;
    const response = await sendUserResponse({
      interaction,
      agentResponse,
      inputValues,
    });
    if (prevInteraction !== interactionRef.current) {
      // The interaction has changed since the request was sent.
      return;
    }
    setWhiteboard([...updatedWhiteboard, response]);
  });

  const messageHistory = whiteboard.map((item) => {
    if (item instanceof AgentResponse) {
      return {
        message: item.response,
        userSent: false,
      }
    } else if (item instanceof WhiteboardItem) {
      return {
        message: item.content,
        userSent: !item.isAgent,
      }
    }
    throw new Error('Unknown item in whiteboard');
  });

  const latestAgentResponse = [...whiteboard]
    .reverse()
    .find((item) => item instanceof AgentResponse);

  const hasObjectiveInputsToRender = !latestAgentResponse && activeObjective.inputFields.length > 0;
  const hasAgentResponseInputsToRender = (
    latestAgentResponse &&
    latestAgentResponse.followUpRequests.length > 0
  );
  const hasInputsToRender = hasObjectiveInputsToRender || hasAgentResponseInputsToRender;

  return (
    <div className={`w-full flex flex-col gap-5 h-screen justify-end`}>
      <PanelGroup direction="vertical">
        <div className="flex flex-col justify-center items-center p-4">
          <div className="text-2xl font-bold">{activeObjective.label}</div>
          <div className="text-slate-400">{activeObjective.description}</div>
        </div>
        <Panel defaultSize={75} className="flex flex-col gap-5 h-screen justify-end">
          {messageHistory.length > 0 && (
            <ChatView
              messageHistory={messageHistory}
              loading={loading}
            />
          )}
        </Panel>
        <PanelResizeHandle>
          <div className="w-full flex justify-center items-center py-2">
            <ChevronUpDownIcon height={18} />
          </div> 
        </PanelResizeHandle>
        <Panel>
          <div className="input-area p-2 pt-4 bg-primary-light-3 h-full overflow-auto">
            {!loading && hasInputsToRender && (
              <div className="flex-grow flex justify-center">
                <div className="max-w-3xl flex-grow flex gap-2">
                  <Avatar />
                  <div className="flex-grow flex flex-col gap-2 pr-10">
                    {!latestAgentResponse && (
                      <ObjectiveInputFieldsRenderer
                        interaction={interaction}
                        inputFields={activeObjective.inputFields}
                        onSubmit={onSubmitObjectiveDirective}
                      />
                    )}
                    {hasAgentResponseInputsToRender && (
                      <ObjectiveInputFieldsRenderer
                        interaction={interaction}
                        inputFields={latestAgentResponse.followUpRequests[0].requiredInputs}
                        onSubmit={(inputValues) => onSubmitUserResponse(latestAgentResponse, inputValues)}
                      />
                    )}
                  </div>
                </div>
              </div>
            )}
            {!loading && !hasInputsToRender && (
              <WhiteboardInputsEmptyState
                onResetInteraction={onResetInteraction}
                onClearObjective={onClearObjective}
              />
            )}
            {loading && (
              <div className="flex justify-center items-center -translate-y-3 h-full">
                <Loader />
              </div>
            )}
          </div>
        </Panel>
      </PanelGroup>
    </div>
  )
};

Whiteboard.propTypes = {
  activeObjective: PropTypes.instanceOf(
    ObjectiveDescription
  ).isRequired,
  interaction: PropTypes.string.isRequired,
};
