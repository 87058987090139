import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { ObjectiveInputField } from '@/model/ObjectiveInputField';
import { ObjectiveInputFieldType } from '@/model/ObjectiveInputFieldType';
import { InputField } from './Common/InputField';
import { InputSelect } from './Common/InputSelect';
import { MessageInput } from './Message/MessageInput';
import { Button } from './Common/Button';
import { Dropzone } from './Common/Dropzone';

const TextInputField = ({ inputField, value, setValue }) => {
  const type = inputField.fieldType === ObjectiveInputFieldType.NUMBER 
    ? 'number' 
    : 'text';
  const cast = inputField.fieldType === ObjectiveInputFieldType.NUMBER
    ? (v) => Number(v)
    : (v) => v;
  return (
    <InputField
      name={inputField.identifier}
      placeholder={inputField.label}
      type={type}
      value={value}
      onChange={(e) => setValue(cast(e.target.value))}
    />
  );
};
TextInputField.propTypes = {
  inputField: PropTypes.instanceOf(ObjectiveInputField).isRequired,
  value: PropTypes.string,
  setValue: PropTypes.func,
};

const ChatTextAreaInputField = ({ inputField, value, setValue, onSubmit }) => {
  return (
    <MessageInput
      messageInput={value}
      placeholder={inputField.label}
      onInputChange={(e) => setValue(e.target.value)}
      onSendMessage={onSubmit}
    />
  );
};
ChatTextAreaInputField.propTypes = {
  inputField: PropTypes.instanceOf(ObjectiveInputField).isRequired,
  onSubmit: PropTypes.func.isRequired,
  value: PropTypes.string,
  setValue: PropTypes.func,
};

const FileInputField = ({ inputField, value, setValue }) => {
  return (
    <Dropzone label={inputField.label} file={value} setFile={setValue} />
  )
};
FileInputField.propTypes = {
  inputField: PropTypes.instanceOf(ObjectiveInputField).isRequired,
};

const ButtonInputField = ({ inputField, onClick }) => {
  return (
    <div className="flex justify-center">
      <Button
        className="!normal-case"
        buttonStyle="default"
        buttonSize="md"
        label={inputField.label}
        onClick={onClick}
      />
    </div>
  );
};
ButtonInputField.propTypes = {
  inputField: PropTypes.instanceOf(ObjectiveInputField).isRequired,
  onClick: PropTypes.func,
};

const SelectInputField = ({ inputField, value, setValue }) => {
  return (
    <InputSelect
      outlined
      placeholder={inputField.label}
      options={inputField.data.options}
      value={value}
      onChange={(option) => setValue(option.value)}
    />
  );
};
SelectInputField.propTypes = {
  inputField: PropTypes.instanceOf(ObjectiveInputField).isRequired,
  value: PropTypes.string,
  setValue: PropTypes.func,
};

const renderInputField = (inputField, inputValues, setValue, onSubmit) => {
  switch (inputField.fieldType) {
    case ObjectiveInputFieldType.TEXT:
    case ObjectiveInputFieldType.NUMBER:
      return (
        <TextInputField
          inputField={inputField}
          value={inputValues[inputField.identifier]}
          setValue={(value) => setValue(inputField, value)}
        />
      );
    case ObjectiveInputFieldType.CHAT_TEXT_AREA:
      return (
        <ChatTextAreaInputField
          inputField={inputField}
          value={inputValues[inputField.identifier]}
          setValue={(value) => setValue(inputField, value)}
          onSubmit={() => onSubmit(inputValues)}
        />
      );
    case ObjectiveInputFieldType.BUTTON:
      const onClick = () => {
        const newInputValues = setValue(inputField, true);
        onSubmit(newInputValues);
      };
      return <ButtonInputField inputField={inputField} onClick={onClick} />;
    case ObjectiveInputFieldType.FILE:
      return (
        <FileInputField
          inputField={inputField}
          value={inputValues[inputField.identifier]}
          setValue={(value) => setValue(inputField, value)}
        />
      );
    case ObjectiveInputFieldType.SELECT:
      return (
        <SelectInputField
          inputField={inputField}
          value={inputValues[inputField.identifier]}
          setValue={(value) => setValue(inputField, value)}
        />
      );
    default:
      throw new Error(`Unsupported input field type: ${inputField.fieldType}`);
  }
};

export const ObjectiveInputFieldsRenderer = ({
  interaction,
  inputFields,
  onSubmit,
}) => {
  const [lastInteraction, setLastInteraction] = useState(null);
  const [inputValues, setInputValues] = useState({});

  useEffect(() => {
    if (lastInteraction !== interaction) {
      const newInputValues = {};
      inputFields.forEach((inputField) => {
        // Check if there is a memorised value.
        if (inputField.memorise) {
          const storedValue = localStorage.getItem(inputField.identifier);
          if (storedValue) {
            newInputValues[inputField.identifier] = storedValue;
            return;
          }
        }
        // Apply default value if there is one.
        if (inputField.defaultValue) {
          newInputValues[inputField.identifier] = inputField.defaultValue;
        }
      });
      setInputValues(newInputValues);
      setLastInteraction(interaction);
    }
  }, [inputFields, interaction, lastInteraction]);

  const setValue = (inputField, value) => {
    // Memorise the value if required.
    if (inputField.memorise) {
      localStorage.setItem(inputField.identifier, value);
    }

    const newInputValues = {
      ...inputValues,
      [inputField.identifier]: value,
    };
    setInputValues(newInputValues);
    return newInputValues;
  };

  // The chat text area already has a submit button.
  const showSubmitButton = inputFields.every(
    (inputField) => inputField.fieldType !== ObjectiveInputFieldType.CHAT_TEXT_AREA
  );

  const submitOnEnterKey =  (e) => {
    // Already handled by the chat text area.
    if (!showSubmitButton) return;

    // Send message on enter.
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      onSubmit(inputValues);
    }
  };

  return (
    <div className="contents" onKeyDown={submitOnEnterKey}>
      {/* Render each of the input fields */}
      {inputFields.map((inputField, index) => (
        <div key={index} className="flex flex-col gap-2">
          {renderInputField(
            inputField,
            inputValues,
            setValue,
            onSubmit
          )}
        </div>
      ))}
      {/* Render a send button (if no chat text area was required) */}
      {showSubmitButton && (
        <div className="flex justify-end">
          <Button label="Submit" onClick={() => onSubmit(inputValues)} />
        </div>
      )}
    </div>
  )
};

ObjectiveInputFieldsRenderer.propTypes = {
  interaction: PropTypes.string.isRequired,
  inputFields: PropTypes.arrayOf(
    PropTypes.instanceOf(ObjectiveInputField)
  ).isRequired,
  onSubmit: PropTypes.func.isRequired,
};
