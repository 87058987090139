import { ObjectiveInputField } from './ObjectiveInputField';

export class UserRequestData {
  constructor(data = {}) {
    this.id = data.id;
    this.prompt = data.prompt;
    this.requiredInputs = data.required_inputs.map(
      (field) => new ObjectiveInputField(field)
    );
  }
}
