import { useDropzone } from "react-dropzone";
import { ArrowUpTrayIcon, TrashIcon } from "@heroicons/react/24/outline";
import { getPathBasename } from "@/utils";

const FileSelected = ({ file, setFile }) => {
  const fileName = file.name ?? getPathBasename(file);
  return (
    <div className="flex justify-center border border-primary-base border-dashed rounded p-8 bg-white">
      <div className="flex flex-col items-center">
        <ArrowUpTrayIcon height={18} className="text-slate-400" />
        <span className="text-slate-600">{fileName}</span>
        <button className="text-red-600 text-sm flex gap-1 items-center" onClick={() => setFile(null)}>
          <TrashIcon className="inline-block" height={14} />
          Remove File
        </button>
      </div>
    </div>
  );
};

// TODO: Allow multiple files.
// TODO: Allow file validation.
const NoFileSelected = ({ label, setFile }) => {
  const { getRootProps, getInputProps } = useDropzone({
    maxFiles: 1,
    onDrop: (acceptedFiles) => {
      setFile(acceptedFiles[0]);
    },
  });
  return (
    <button {...getRootProps()} className="flex justify-center border border-primary-base border-dashed rounded p-8 bg-white">
      <div className="flex flex-col items-center">
        <ArrowUpTrayIcon height={18} className="text-slate-400" />
        <span className="text-slate-600">{ label }</span>
        <span className="text-slate-400 text-sm">Browse or drag and drop</span>
      </div>
      <input {...getInputProps()} />
    </button>
  )
};

export const Dropzone = ({ label, file, setFile }) => {
  return file 
    ? <FileSelected file={file} setFile={setFile} /> 
    : <NoFileSelected label={label} setFile={setFile} />;
};
