import PropTypes from "prop-types";
import { useEffect } from "react";
import { MessageRow } from "@/components/Message/MessageRow";

export const ChatView = ({
  messageHistory,
  downloadMessage,
  loading,
}) => {
  const scrollToNewestChat = () => {
    const chatDiv = document.getElementById("chatView");
    chatDiv.scroll({ top: chatDiv.scrollHeight, behavior: "smooth" });
  };

  useEffect(() => {
    scrollToNewestChat();
  });

  return (
    <div id="chatView" className="px-2 pt-2 max-h-full overflow-y-auto">
      <div className="flex justify-center">
        <div className="flex flex-col gap-5 flex-1 justify-end max-w-3xl items-center">
          {messageHistory.map((history, index) => (
            <div key={index} className="w-full">
              <MessageRow
                message={history.message}
                userSent={history.userSent}
                onDownload={() => downloadMessage(history)}
              />
            </div>
          ))}
          {loading && <div className="w-full"><MessageRow loading /></div>}
        </div>
      </div>
    </div>
  );
};

ChatView.propTypes = {
  messageHistory: PropTypes.array,
  downloadMessage: PropTypes.func,
  loading: PropTypes.bool,
};
