import PropTypes from "prop-types";

export const InfoButton = ({ buttonText, info, active, onClick, ...props }) => {
  const classNames = [
    "rounded capitalize transition ease-in-out tracking-wide text-white hover:bg-primary-light w-full",
    active ? "bg-primary-base" : "",
  ].join(" ");

  return (
    <div className={classNames}>
      <button
        className="grid gap-2 px-6 py-3 w-full text-left"
        {...props}
        onClick={onClick}
      >
        <div className="font-bold">{buttonText}</div>
        <div className="text-sm text-slate-300">{info}</div>
      </button>
    </div>
  );
};

InfoButton.propTypes = {
  buttonText: PropTypes.string,
  info: PropTypes.string,
  active: PropTypes.bool,
  onClick: PropTypes.func,
};
