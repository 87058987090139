import SelectionImage from "@/assets/selection.svg";

export const WhiteboardEmptyState = () => (
  <div className="p-2 max-w-7xl w-full text-center flex flex-col gap-5 h-screen justify-center">
    <img
      src={SelectionImage}
      className="mx-auto max-w-md"
      alt="Select your role and objective"
    />
    <div className="font-medium text-xl mx-auto max-w-xs">
      Select your role and objective in the sidebar to get started!
    </div>
  </div>
);
