import PropTypes from "prop-types";
import { InputField } from "@/components/Common/InputField";
import { PaperAirplaneIcon } from "@heroicons/react/24/solid";

export const MessageInput = ({
  messageInput,
  onInputChange,
  onSendMessage,
  loading,
  placeholder,
  disabled,
}) => {
  return (
    <div className="w-full flex gap-x-2 relative">
      <div className="flex-grow">
        <InputField
          name="messageInput"
          type="textarea"
          placeholder={placeholder}
          disabled={loading || disabled}
          value={messageInput}
          onChange={onInputChange}
          onKeyDown={(e) => {
            if (e.key === "Enter" && !e.shiftKey) {
              e.preventDefault();
              onSendMessage();
            }
          }}
        />
      </div>
      <div className="absolute right-4 bottom-6">
        <div className="h-6 w-6">
          <PaperAirplaneIcon
            className={`mt-1 transition ease-in-out ${
              loading
                ? "animate-pulse text-primary-light cursor-not-allowed"
                : "text-primary-base hover:text-primary-light cursor-pointer"
            }`}
            onClick={() => {
              if (!loading) onSendMessage();
            }}
          />
        </div>
      </div>
    </div>
  );
};

MessageInput.propTypes = {
  messageInput: PropTypes.string,
  onInputChange: PropTypes.func.isRequired,
  onSendMessage: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  showClearHistory: PropTypes.bool,
};

MessageInput.defaultProps = {
  placeholder: "Send a message...",
};
