import React from "react";
import PropTypes from "prop-types";
import "./button.css";

/**
 * Primary UI component for user interaction
 */
export const Button = ({
  className,
  buttonStyle,
  buttonSize,
  backgroundColor,
  label,
  disabled,
  loading,
  outlined,
  active,
  ...props
}) => {
  const classes = [
    "button",
    `button--${buttonStyle}`,
    `button--${buttonSize}`,
    active ? "button--active" : "",
    className,
  ].join(" ");

  return (
    <button
      type="button"
      className={classes}
      style={backgroundColor && { backgroundColor }}
      disabled={disabled || loading}
      {...props}
    >
      <div className="grid grid-flow-col gap-2 justify-center items-center">
        {label}
      </div>
    </button>
  );
};

Button.propTypes = {
  /**
   * Additional class names to be added to the component
   */
  className: PropTypes.string,
  /**
   * What style to use:
   * - default: white background, grey text
   * - primary: blue background, white text
   * - alert: white background, red text
   */
  buttonStyle: PropTypes.oneOf(["default", "primary", "secondary", "alert"]),
  /**
   * What style to use:
   * - default: white background, grey text
   * - primary: blue background, white text
   * - alert: white background, red text
   */
  buttonSize: PropTypes.oneOf(["xs", "sm", "md", "lg"]),
  /**
   * What background color to use
   */
  backgroundColor: PropTypes.string,
  /**
   * Button contents
   */
  label: PropTypes.any.isRequired,
  /**
   * Disables the button
   */
  disabled: PropTypes.bool,
  /**
   * Enables loading state
   */
  loading: PropTypes.bool,
  /**
   * Optional click handler
   */
  onClick: PropTypes.func,
  outlined: PropTypes.bool,
  /**
   * Outlined icon
   */
  active: PropTypes.bool,
};

Button.defaultProps = {
  className: "",
  buttonStyle: "default",
  buttonSize: "md",
  backgroundColor: null,
  disabled: false,
  loading: false,
  onClick: undefined,
  outlined: false,
  active: false,
};
