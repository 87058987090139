import React, { createContext, useContext, useState, useLayoutEffect } from 'react';
import jwtDecode from 'jwt-decode';
import { axiosInstance } from '@/utils/Axios';

const JWT_LOCAL_STORAGE_KEY = 'jwt';

const AuthContext = createContext();

// Get the jwt from local storage if it exists
let initialToken = localStorage.getItem(JWT_LOCAL_STORAGE_KEY);
if (initialToken) {
  initialToken = JSON.parse(initialToken);
}

const validateClaims = (claims) => {
  const now = Date.now() / 1000;
  return claims.exp > now;
}

function AuthProvider({ children }) {
  const [jwt, setJwt] = useState(initialToken);
  const [claims, setClaims] = useState(null);

  useLayoutEffect(() => {
    if (jwt) {
      const claims = jwtDecode(jwt.access_token);
      if (validateClaims(claims)) {
        axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${jwt.access_token}`;
        setClaims(claims);
      } else {
        logout();
      }
    } else {
      delete axiosInstance.defaults.headers.common['Authorization'];
    }
  }, [jwt, setClaims]);

  const setJwtAndStore = (jwt) => {
    setJwt(jwt);
    localStorage.setItem(JWT_LOCAL_STORAGE_KEY, JSON.stringify(jwt));
  };

  const logout = () => {
    setJwt(null);
    localStorage.removeItem(JWT_LOCAL_STORAGE_KEY);
  };

  return (
    <AuthContext.Provider value={{ 
      loggedIn: !!jwt,
      jwt,
      setJwt: setJwtAndStore,
      logout,
      userId: claims?.sub,
    }}>
      {children}
    </AuthContext.Provider>
  );
}

// Create a Hook to use our AuthContext, this is just a shortcut
function useAuth() {
  return useContext(AuthContext);
}

export { AuthProvider, useAuth };
