import {
  createHashRouter
} from "react-router-dom";

import { GuardedRoute } from "@/components/Common/GuardedRoute";
import { Home } from "@/pages/Home";
import { Login } from "@/pages/Login";
import { Logout } from "@/pages/Logout";

const guardedRoute = ({ path, element, ...props }) => (
  {
    path,
    element: (
      <GuardedRoute>
        {element}
      </GuardedRoute>
    ),
    ...props,
  }
);

export const router = createHashRouter([
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/logout",
    element: <Logout />,
  },
  guardedRoute({
    path: "*",
    element: <Home />,
  }),
]);
